import { OrderStates } from '../../../modules/stamped-io/types/OrderStates'

export function orderStateToColor (state: OrderStates) {
  const colorStateMap = {
    [OrderStates.New]: '--c-info',
    [OrderStates.Processing]: '--c-info',
    [OrderStates.Holded]: '--c-warning',
    [OrderStates.Complete]: '--c-success',
    [OrderStates.Closed]: '--c-danger',
    [OrderStates.Canceled]: '--c-danger'
  };
  return colorStateMap[state] || '--c-warning';
}
